import { SERVER_OFFSET } from '@/constants/config'

const OrderRenderMixin = {
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currency() {
      return this.$store.getters['auth/user']?.currency ?? 'sp'
    },
    disabled() {
      return this.form.status === this.order.status && this.form.paid === this.order.payment_status
    },
    today() {
      return this.$moment()
    },
    time() {
      const time = this.order?.created_at_full
      if (!time) return ''

      return this.$moment(time, 'YYYY-M-D hh:mm A').tz(this.$tz).format('DD/MM/YYYY hh:mm A')
    },
    orderTime() {
      const time = this.order?.created_at_full
      console.log(time)
      if (!time) return ''

      return this.$moment(time, 'YYYY-M-D hh:mm A').tz(this.$tz).format('hh:mm A')
    },
    orderDate() {
      const time = this.order?.created_at_full
      if (!time) return ''

      return this.$moment(time, 'YYYY-M-D hh:mm A').tz(this.$tz).format('MMMM D [,]YYYY')
    },
    scheduledAt() {
      return this.order?.scheduled_at ? this.$moment(`${this.order.scheduled_at} ${SERVER_OFFSET}`, 'YYYY-MM-DD HH:mm Z').tz(this.$tz).format('DD/MM/YYYY hh:mm A') : '-'
    },
    scheduledTime() {
      return (!this.order?.scheduled_at || this.order.scheduled_at === '-') ? '' : this.$moment(`${this.order.scheduled_at} ${SERVER_OFFSET}`, 'YYYY-MM-DD HH:mm Z').tz(this.$tz).format('hh:mm A')
    },
    originalCurrency() {
      return this.order?.items?.[0]?.currency ?? this.currency
    },
  },
}

export default OrderRenderMixin
