<template>
  <div id="receipt-template">
    <div
      v-if="receiptSettings && receiptSettings.id"
      class="receipt-template-container"
    >
      <section>
        <div class="receipt-title">
          {{ receiptSettings.receipt_title || $t('orders.order-receipt') }}
        </div>
      </section>

      <section>
        <div
          v-if="receiptLogo"
          class="receipt-logo"
        >
          <img
            :src="receiptLogo"
            height="80"
            :alt="businessName"
          >
        </div>
        <div class="receipt-business-name">
          {{ businessName }} - {{ currentBranchName }}
        </div>
      </section>

      <section>
        <div class="receipt-number-label">
          {{ $t('orders.order-no') }}
        </div>
        <div class="receipt-number">
          #{{ order.id }}
        </div>
      </section>

      <section>
        <div class="order-type">
          {{ orderType }}
        </div>
        <div v-if="order.schedule">
          {{ order.schedule }}
        </div>
      </section>

      <div v-if="deliveryQr">
        <div class="divider" />

        <img
          :src="deliveryQr"
          class="delivery-qr"
          alt="Delivery Qr"
        >
      </div>

      <div class="divider" />

      <section class="p-b-5">
        <div class="p-b-5">
          {{ order.name }}
        </div>
        <div
          class="p-b-5"
          v-html="orderTypeDetails"
        />
        <div
          v-if="order.note"
          class="p-b-5"
        >
          {{ $t('general.note') }}: {{ order.note }}
        </div>
        <div
          v-if="order.phone"
          class="p-b-5"
          dir="ltr"
        >
          tel: {{ order.phone }}
        </div>
      </section>

      <div class="divider" />

      <section v-if="order.items">
        <div
          v-for="line in order.items"
          :key="line.id"
          :class="{'p-b-5': order.items.length > 1}"
        >
          <div class="text-left">
            <div class="d-flex justify-content-between flex-between">
              <div :class="`${isMainEnabled(line.item) && isLocalizedEnabled(line.item) ? 'three-lines' : 'two-lines'}`">
                <div v-if="isMainEnabled(line.item)">
                  {{ line.quantity }} x {{ getMainLanguageName(line.item) }}
                </div>
                <div v-if="isLocalizedEnabled(line.item)">
                  <span v-if="!isMainEnabled(line.item)">{{ line.quantity }}</span> {{ getLocalizedLanguageName(line.item) }}
                </div>
                <div v-if="line.comment">
                  {{ noteLabel(line) }}: {{ line.comment }}
                </div>
              </div>
              <div
                class="nowrap"
                :dir="currency==='sp'?'rtl':'ltr'"
              >
                {{ line.quantity * (line.discounted_price || line.price) }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
              </div>

            </div>

            <div
              v-for="modifier in line.modifiers"
              :key="modifier.id"
              class="d-flex justify-content-between flex-between modifier"
            >
              <div :class="`${isMainEnabled(modifier) && isLocalizedEnabled(modifier) ? 'three-lines' : 'two-lines'}`">
                <div v-if="isMainEnabled(modifier)">
                  {{ getMainLanguageName(modifier) }}  * {{ modifier.quantity ? modifier.quantity : 1 }}
                </div>
                <div v-if="isLocalizedEnabled(modifier)">
                  {{ getLocalizedLanguageName(modifier) }} * {{ modifier.quantity ? modifier.quantity : 1 }}
                </div>

              </div>
              <div
                v-if="modifier.price"
                class="nowrap"
                :dir="currency==='sp'?'rtl':'ltr'"
              >
                {{ line.quantity * (modifier.discounted_price || modifier.price) }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="divider" />

      <section>
        <div
          v-if="receiptSettings.display_subtotal"
          class="d-flex justify-content-between flex-between"
        >
          <div>{{ $t('orders.sub-total') }}:</div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            {{ (order.subtotal) }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
          </div>
        </div>
        <div
          v-if="discountAmount"
          class="d-flex justify-content-between flex-between"
        >
          <div>{{ $t('orders.discount') }}:</div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            {{ discountAmount }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
          </div>
        </div>
        <div
          v-if="order.coupon"
          class="d-flex justify-content-between flex-between"
        >
          <div>{{ $t('orders.coupon') }}:</div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            <template v-if="order.coupon.discount_type === 'fixed'">
              {{ order.coupon.discount_value }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
            </template>
            <template v-else>
              {{ order.coupon.discount_value }} %
            </template>
          </div>
        </div>
        <div
          v-if="isDelivery && order.delivery_fees"
          class="d-flex justify-content-between flex-between"
        >
          <div>{{ $t('orders.delivery-fees') }}:</div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            {{ order.delivery_fees }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
          </div>
        </div>
        <div
          v-if="order.taxes_amount"
          class="d-flex justify-content-between flex-between"
        >
          <div>{{ $t('orders.taxes') }}:</div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            {{ order.taxes_amount }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
          </div>
        </div>
      </section>

      <div class="divider" />

      <section>
        <div class="d-flex justify-content-between flex-between receipt-total">
          <div>
            {{ $t('orders.total') }}:
          </div>
          <div
            class="nowrap"
            :dir="currency==='sp'?'rtl':'ltr'"
          >
            {{ `${order.total}` }} <small>{{ originalCurrency }}</small>&nbsp;&nbsp;
          </div>
        </div>
      </section>

      <div class="divider" />

      <section class="receipt-status">
        {{ order.paid ? $t('subscription.paid').toUpperCase() : $t('subscription.unpaid').toUpperCase() }}
      </section>
      <div class="divider" />

      <section>
        <div>
          {{ $t('orders.ordered-at') }}: {{ orderTime }}
        </div>
        <div>
          {{ orderDate }}
        </div>

        <div v-if="menuQr && receiptSettings.display_QR">
          <div class="divider" />

          <img
            :src="menuQr"
            class="delivery-qr"
            alt="Menu Qr"
          >
        </div>
      </section>
      <div class="divider" />
      <section>
        {{ receiptSettings.receipt_footer || $t('orders.thanks-see-soon') }}
      </section>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import LocalizationService from '@/services/localization-service'
import { PrintLanguages } from '@/constants/design-settings-options'
import OrderRenderMixin from '../mixins/order-render-mixin'
import OrdersMixin from '../mixins/OrdersMixin'
import OrderScheduleMixin from '../mixins/schedule-mixin'

export default {
  name: 'ReceiptTemplate',
  mixins: [OrderRenderMixin, OrdersMixin, OrderScheduleMixin],
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      deliveryQr: null,
      menuQr: null,
    }
  },
  computed: {
    discountAmount() {
      return this.order.total - this.order.total_no_discount
    },
    isDelivery() {
      return this.order.order_type === 'delivery'
    },
    orderType() {
      return this.$t(`orders.types.${this.order.order_type}`)
    },
    businessName() {
      return this.$store.getters['auth/user'].business_name ?? ''
    },
    currentBranchName() {
      const currentBranch = this.$store.getters['shop/currentBranch']
      return currentBranch.ar_name ?? currentBranch.en_name ?? ''
    },
    receiptLogo() {
      return this.$store.getters['auth/printSettings'].receipt_logo
    },
    receiptSettings() {
      return this.$store.getters['auth/printSettings']
    },
    orderTypeDetails() {
      if (this.order?.table) {
        return `<span class="font-weight-semibold">${this.order?.table}</span>${this.order?.floor ? `, ${this.order?.floor?.name}` : ''}`
      }
      const { city, address } = this.order
      if (city) {
        return `<span class="font-weight-semibold">${this.$t('fields.city')}</span>: ${this.$t(`cities.${city}`)}`
      }
      if (address) {
        return `<span class="font-weight-semibold">${this.$t('fields.address')}</span>: ${address}`
      }
      return ''
    },
  },
  mounted() {
    const webappUrl = this.$store.getters['auth/user']?.app_url
    QRCode.toDataURL(webappUrl)
      .then(qr => { this.menuQr = qr })
      .catch(err => { this.$notifyError(err) })
  },
  methods: {
    getMainLanguageName(item) {
      return (item.locales ?? []).find(el => el.locale === this.receiptSettings.main_language)?.name || item?.locales[0]?.name
    },
    getLocalizedLanguageName(item) {
      return (item.locales ?? []).find(el => el.locale === this.receiptSettings.localized_language)?.name
    },
    isMainEnabled(item) {
      const pr = this.receiptSettings.print_language
      return this.getMainLanguageName(item) && (pr == null || pr === PrintLanguages.MAIN_AND_LOCALIZED.id || pr === PrintLanguages.MAIN.id)
    },
    isLocalizedEnabled(item) {
      const pr = this.receiptSettings.print_language
      return this.getLocalizedLanguageName(item) && (
        pr === PrintLanguages.MAIN_AND_LOCALIZED.id || pr === PrintLanguages.LOCALIZED.id)
    },
    async prepareDocument() {
      this.deliveryQr = null
      if (!this.order?.id || !this.isDelivery || !this.order?.location?.lat) return true

      try {
        const { lat, lng } = this.order?.location ?? {}
        const zoom = 17
        const delivery = `https://maps.google.com/maps?&z=${zoom}&q=${lat}+${lng}&ll=${lat}+${lng}`
        this.deliveryQr = await QRCode.toDataURL(delivery)
      } catch (err) {
        this.$notifyError(err)
      }

      return true
    },
    noteLabel(line) {
      return LocalizationService.getTranslate(line?.item?.category, 'note', this.$i18n.locale, true) ? LocalizationService.getTranslate(line?.item?.category, 'note', this.$i18n.locale, true) : this.$t('orders.notes')
    },
  },
}
</script>
