<template>
  <div id="form-group">
    <div
      ref="googleMap"
      :style="{height:height+'px'}"
      class="google-map w-100"
    />

  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import { GOOGLE_MAK_KEY } from '@/constants/config'

export default {
  name: 'GoogleMap',
  props: {
    mapConfig: {
      type: Object,
      default: () => {
      },
    },
    height: {
      type: Number,
      default: 250,
    },
    marker: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      google: null,
      map: null,
      config: {},
      mapMarker: null,
    }
  },
  async mounted() {
    this.config = this.mapConfig
    this.google = await GoogleMapsApiLoader({
      apiKey: GOOGLE_MAK_KEY,
    })
    this.initializeMap()
  },
  methods: {
    initializeMap() {
      const self = this
      const mapContainer = this.$refs.googleMap
      if (!self?.config?.center?.lat) {
        self.config.center.lat = 33.510414
        self.config.center.lng = 36.278336
      } else {
        self.config.center.lat = parseFloat(self.config.center.lat)
        self.config.center.lng = parseFloat(self.config.center.lng)
      }
      this.map = new this.google.maps.Map(mapContainer, this.config)
      if (this.marker) {
        this.mapMarker = new this.google.maps.Marker({
          position: this.config.center,
          map: self.map,
          draggable: false,
          animation: this.google.maps.Animation.DROP,
        })
      }
    },
  },
}
</script>
