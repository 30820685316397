import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import { OrderStatusOptions, PaymentStatusList, OrderTypesList } from '@/views/orders/config/order-options'
import Cities from '@/constants/cities'
import store from '@/store'
import LocalizationService from '@/services/localization-service'

const OrdersTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/orders`,
  rowsPath: 'orders',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'orders.id',
      field: 'id',
    },
    {
      label: 'orders.customer',
      field: 'customer_info',
    },
    {
      label: 'orders.order-type',
      field: 'order_type',
      formatFn: (val?: string) => LocalizationService.translate(`orders.types.${val ?? 'service'}`),
    },
    {
      label: 'fields.branch',
      field: 'branch',
      formatFn: (val: any) => val?.ar_name ?? '',

    },
    {
      label: 'orders.order-status',
      field: 'status',
    },
    {
      label: 'orders.amount',
      field: 'amount',
    },
    {
      label: 'orders.payment-status',
      field: 'paid',
    },
    {
      label: 'orders.order-time',
      field: 'created_at',
    },
    {
      label: 'orders.schedule',
      field: 'schedule',
      formatFn: (val: any) => (val === '' ? LocalizationService.translate('orders.asap') : val),
    },
    {
      label: 'forms.actions',
      field: 'actions',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
  ],
  filters: [
    {
      label: 'orders.order-id',
      query: 'number',
      colLength: 3,
    },
    {
      label: 'orders.customer-info',
      query: 'customer',
      colLength: 3,
    },
    {
      label: 'fields.city',
      query: 'cities',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: Cities,
      optionsLabel: { keyLabel: 'text', valueLabel: 'value', translate: true },
    },
    {
      label: 'fields.branch',
      query: 'branch',
      colLength: 3,
      type: 'select',
      multiple: false,
      options: store.getters['auth/branches'],
      optionsLabel: { keyLabel: 'ar_name', valueLabel: 'id', translate: false },
    },
    {
      label: 'orders.order-type',
      query: 'order_type',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: OrderTypesList,
      optionsLabel: { keyLabel: 'label', valueLabel: 'value', translate: true },
    },
    {
      label: 'orders.order-status',
      query: 'status',
      colLength: 3,
      type: 'select',
      multiple: true,
      options: OrderStatusOptions,
      optionsLabel: { keyLabel: 'label', valueLabel: 'value', translate: true },
    },
    {
      label: 'orders.payment-status',
      query: 'paid',
      colLength: 3,
      type: 'select',
      options: PaymentStatusList,
      optionsLabel: { keyLabel: 'label', valueLabel: 'value', translate: true },
    },
    {
      label: 'orders.amount',
      query: 'amount',
      type: 'range',
      range_type: 'number',
      colLength: 3,
    },
    {
      label: 'orders.order-time',
      query: 'created_at',
      colLength: 3,
      type: 'range',
      range_type: 'date',
    },
  ],
}

export default OrdersTableConfig
