import { SERVER_OFFSET } from '@/constants/config'

const OrderScheduleMixin = {
  methods: {
    formattedScheduledAt(order, forTime = false) {
      if (!order.scheduled_date) return ''
      const date = this.$moment(`${order.scheduled_date} ${SERVER_OFFSET}`, 'YYYY-MM-DD').tz(this.$tz)
      if (order.scheduled_slot) return `${forTime ? '' : `${date.format('DD/MM/YYYY')} | `} ${order.scheduled_slot.from} - ${order.scheduled_slot.to}`
      return date.format(forTime ? 'hh:mm A' : 'DD/MM/YYYY hh:mm A')
    },
  },
}

export default OrderScheduleMixin
