var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ok-table',{ref:"datatable",attrs:{"config":_vm.config,"title":_vm.$t('orders.title')},on:{"update:total":_vm.checkFilter},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"info"},on:{"click":_vm.exportExcel}},[_c('FeatherIcon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t('forms.export')))])],1),_c('b-button',{staticClass:"d-flex align-items-center mx-1",attrs:{"variant":"dark"},on:{"click":function($event){return _vm.$router.push({name:'owner:print'})}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t('invoice-settings')))])])]},proxy:true},{key:"status-column",fn:function(ref){
var props = ref.props;
return [_c('order-status-select',{attrs:{"value":props.row.status,"dropdown":""},on:{"input":function($event){return _vm.handleChangeOrderStatus($event, props.row)}}})]}},{key:"customer_info-column",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(props.row.name === 'guest' ? _vm.$t('orders.guest') : props.row.name)+" "),_c('div',{staticClass:"text-nowrap"},[_c('a',{staticClass:"text-secondary",attrs:{"href":("tel:" + (props.row.phone)),"dir":"ltr"}},[_vm._v(" "+_vm._s(props.row.phone)+" ")])])]}},{key:"amount-column",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(((props.row.total) + " " + _vm.currency))+" ")]}},{key:"paid-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"checked":props.row.paid,"unchecked-value":0,"value":1,"switch":""},on:{"input":function($event){return _vm.updateStatus($event,props.row.id)}}})]}},{key:"actions-column",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-1",attrs:{"title":_vm.$t('pagination.show'),"size":"sm","variant":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openOrderModal(props.row)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('b-button',{staticClass:"btn-icon rounded-circle mr-1",attrs:{"title":_vm.$t('forms.delete'),"size":"sm","variant":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(props.row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1),_c('b-button',{staticClass:"btn-icon rounded-circle mr-1",attrs:{"title":_vm.$t('actions.print'),"size":"sm","variant":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.printReceipt(props.row)}}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}})],1)],1)]}}])}),_c('order-table',{attrs:{"order":_vm.selectedOrder},model:{value:(_vm.showOrderModal),callback:function ($$v) {_vm.showOrderModal=$$v},expression:"showOrderModal"}}),_c('div',{staticClass:"receipt"},[_c('receipt-template',{ref:"receipt",attrs:{"order":_vm.selectedOrder}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }