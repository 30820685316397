import isPlainObject from 'lodash/isPlainObject'
import LocalizationService from '@/services/localization-service'

export default {
  data() {
    return {
      order_type: '',
      payment_type: '',
    }
  },
  methods: {
    getTrans(str, attr = {}) {
      return this.$t(`qr-code.${str}`, attr)
    },
    getOrderTypeLabel(order, fromCom = 'invoice') {
      const {
        car_info, table, address,
      } = order
      let details = null
      let label = this.$store.getters['businessTypes/tableLabelPath']
      if (table) {
        label = this.$store.getters['businessTypes/tableLabelPath']
        details = `<span class="font-weight-bold">${table?.name}</span>${table?.floor ? `, ${table?.floor?.name}` : ''}`
      } else if (address && isPlainObject(address)) {
        const formFields = ['city', 'street', 'block', 'area', 'parking', 'note', 'house_number', 'office_number', 'building', 'avenue', 'floor', 'apartment', 'paci', 'special_direction']
        details = ''
        formFields.forEach(field => {
          if (address[field]) details += `<span class="font-weight-bold">${this.$t(`orders.address-details.${field}`)}</span>: ${address[field]}, `
        })
        label = 'orders.address'
      } else if (car_info && isPlainObject(car_info) && (car_info?.color || car_info?.model)) {
        label = 'orders.car-info'
        details = `${car_info?.color && car_info?.color !== 'null' ? `<span class="font-weight-bold">${car_info?.color || ''}</span>,` : ''} ${car_info?.model && car_info?.model !== 'null' ? car_info?.model : ''}`
      }
      if (!details) return ''
      if (fromCom === 'modal') return `<span class="font-weight-bold mr-1">${this.$t(label)}: </span> ${details}`
      return `<p class="invoice-date-title mr-1">${this.$t(label)}: </p><p class="invoice-date"> ${details}</p>`
    },
    getModifierName(modifier) {
      return LocalizationService.getTranslate(modifier)
    },
    payments(order) {
      let { payments } = order
      if (!payments) {
        if (order.payment_status_key) {
          payments = [{ payment_type: order.payment_type_key, payment_id: order.payment_id || '', amount: order.cash_amount || order.original_amount || order.amount }]
        } else {
          payments = []
        }
      }
      return payments
    },
  },
}
