<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('orders.title')"
      @update:total="checkFilter"
    >
      <template #buttons>
        <b-button
          class="d-flex align-items-center"
          variant="info"
          @click="exportExcel"
        >
          <FeatherIcon
            class="mr-50"
            icon="DownloadIcon"
          />
          <span class="align-middle"> {{ $t('forms.export') }}</span>
        </b-button>
        <b-button
          class="d-flex align-items-center mx-1"
          variant="dark"
          @click="$router.push({name:'owner:print'})"
        >
          <span class="align-middle"> {{ $t('invoice-settings') }}</span>
        </b-button>
      </template>
      <template
        v-slot:status-column="{props}"
      >
        <order-status-select
          :value="props.row.status"
          dropdown
          @input="handleChangeOrderStatus($event, props.row)"
        />
      </template>
      <template
        v-slot:customer_info-column="{props}"
      >
        {{ props.row.name === 'guest' ? $t('orders.guest') : props.row.name }}
        <div
          class="text-nowrap"
        >
          <a
            :href="`tel:${props.row.phone}`"
            class="text-secondary"
            dir="ltr"
          >
            {{ props.row.phone }}
          </a>
        </div>

      </template>
      <template
        v-slot:amount-column="{props}"
      >
        {{ `${props.row.total} ${currency}` }}
      </template>
      <template
        v-slot:paid-column="{props}"
      >
        <b-form-checkbox
          :checked="props.row.paid"
          :unchecked-value="0"
          :value="1"
          switch
          @input="updateStatus($event,props.row.id)"
        />

      </template>
      <template
        v-slot:actions-column="{props}"
      >
        <div class="d-flex">
          <b-button
            :title="$t('pagination.show')"
            class="btn-icon rounded-circle mr-1"
            size="sm"
            variant="primary"
            @click.stop="openOrderModal(props.row)"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
          <b-button
            :title="$t('forms.delete')"
            class="btn-icon rounded-circle mr-1"
            size="sm"
            variant="danger"
            @click.stop="handleDelete(props.row)"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
          <b-button
            :title="$t('actions.print')"
            class="btn-icon rounded-circle mr-1"
            size="sm"
            variant="success"
            @click.stop="printReceipt(props.row)"
          >
            <feather-icon
              icon="PrinterIcon"
            />
          </b-button>
        </div>
      </template>

    </ok-table>
    <order-table
      v-model="showOrderModal"
      :order="selectedOrder"
    />
    <div class="receipt">
      <receipt-template
        ref="receipt"
        :order="selectedOrder"
      />
    </div>
  </div>
</template>

<script>
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import OrdersAPIs from '@/api/store/order-api'
import printJS from 'print-js'
import ReceiptTemplate from '@/views/orders/components/ReceiptTemplate'
import OrdersTableConfig from './config/orders-table-config'
import OrderStatusSelect from './components/OrderStatusSelect'
import { OrderStatusOptions } from './config/order-options'
import OrderTable from './components/OrderModal'

export default {
  components: {
    ReceiptTemplate,
    OkTable,
    BButton,
    OrderStatusSelect,
    OrderTable,
    BFormCheckbox,
  },
  data() {
    return {
      config: OrdersTableConfig,
      showOrderModal: false,
      selectedOrder: {},
      receipt: {},
      byFilter: true,
      firstRender: true,
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/user']?.currency ?? 'sp'
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/GET_PRINT_SETTINGS')
  },
  methods: {
    checkFilter() {
      this.firstRender = false
      if (this.byFilter && this.$route.query) {
        this.byFilter = false
        this.$refs.datatable.loadItems(this.$route.query)
      }
    },
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => OrdersAPIs.delete(data.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    setUnpaidOrders(e) {
      this.selectedOrders = (e?.selectedRows ?? []).filter(order => order.payment_status === 0)
    },
    openOrderModal(order) {
      this.showOrderModal = true
      this.selectedOrder = order
    },
    handleChangeOrderStatus(newStatus, order) {
      this.$okConfirm(this.$t('orders.change-status-confirmation'), {
        contentComponent: () => import('@/views/orders/components/OrderStatusChangeConfirm'),
        componentProps: {
          orderId: order.id,
          oldStatus: this.$t(OrderStatusOptions.find(option => option.value === order.status)?.label ?? order.status),
          newStatus: this.$t(OrderStatusOptions.find(option => option.value === parseInt(newStatus, 10))?.label ?? newStatus),
          text: this.$t('orders.change-status-confirmation'),
          orderLabel: this.$t('orders.order-no'),
          rtl: this.isRTL,
        },
        size: 'sm',
        showCancel: true,
        disableOutsideClick: false,
        inlineActions: true,
        confirmButtonText: this.$t('forms.confirm'),
        showLoaderOnConfirm: true,
        text: '',
        preConfirm: () => OrdersAPIs.process({ id: order.id, status: newStatus }).then(res => {
          this.$notifySuccess(res.message)
          this.refreshTable()
        })
          .catch(err => {
            if (err?.response?.status === 409) {
              this.$notifyWarning(err?.response?.data?.message ?? '')
            } else {
              this.$swalError(err)
            }
          }),
      })
    },
    exportExcel() {
      OrdersAPIs.export(this.$refs.datatable.$refs.filters.$data.queries)
        .catch(e => this.$notifyError(e))
    },
    updateStatus(value, id) {
      if (this.firstRender) {
        return
      }
      OrdersAPIs.process({ id, paid: value })
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
    printReceipt(order) {
      this.selectedOrder = order
      const styles = ['/css/receipt.css']

      if (this.isRTL) {
        styles.push('/css/receipt-rtl.css')
      }

      this.$nextTick(() => this.$refs.receipt.prepareDocument().then(() => printJS({
        printable: 'receipt-template', type: 'html', font_size: '', scanStyles: false, css: styles,
      })))
    },
  },
}
</script>
<style scoped>
.receipt {
  width: 80mm;
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
</style>
