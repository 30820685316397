<template>
  <div class="order-status-select-container">
    <b-dropdown
      v-if="dropdown"
      :disabled="!options.length"
      :no-caret="!options.length"
      size="sm"
      class="w-100"
      :right="!isRTL"
      split-class="w-100"
      :text="$t(selected.label)"
      :variant="getDropdownColor(selected)"
    >
      <b-dropdown-item
        v-for="(option, idx) in options"
        :key="idx"
        class="d-flex justify-content-between align-items-center"
        @click="handleChange(option.value)"
      >
        <small>{{ $t('orders.transition-to') }}</small>
        <feather-icon
          :icon="isRTL ? 'ArrowLeftIcon' : 'ArrowRightIcon'"
          class="arrow-icon"
        />
        <b-badge :variant="getBadgeColor(option)">
          {{ $t(option.label) }}
        </b-badge>
      </b-dropdown-item>
      <b-dropdown-divider v-if="false && selected.value !== pendingOption.value && options.length" />
      <b-dropdown-item
        v-if="false && selected.value !== pendingOption.value"
        class="d-flex justify-content-between align-items-center"
        @click="handleChange(pendingOption.value)"
      >
        <small>{{ $t('orders.back-to') }}</small>
        <feather-icon
          :icon="isRTL ? 'ArrowLeftIcon' : 'ArrowRightIcon'"
          class="arrow-icon"
        />
        <b-badge :variant="getBadgeColor(pendingOption.color)">
          {{ $t(pendingOption.label) }}
        </b-badge>
      </b-dropdown-item>
    </b-dropdown>

    <v-select
      v-else
      :disabled="disabled"
      :options="selectOptions"
      :reduce="s => s.value"
      :filterable="false"
      :searchable="false"
      label="label"
      :clearable="false"
      :value="status"
      :dir="isRTL ? 'rtl' : 'ltr'"
      class="order-status-select w-100"
      @input="handleChange"
    >
      <template #selected-option>
        <b-badge :variant="getBadgeColor(selected)">
          {{ $t(selected.label) }}
        </b-badge>
      </template>
      <template #option="{ label, color, value }">
        <template v-if="value !== selected.value">
          <feather-icon
            :icon="isRTL ? 'ArrowLeftIcon' : 'ArrowRightIcon'"
            class="arrow-icon"
          />
        </template>

        <b-badge :variant="getBadgeColor({color})">
          {{ $t(label) }}
        </b-badge>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  VBTooltip, BBadge, BDropdown, BDropdownDivider, BDropdownItem,
} from 'bootstrap-vue'
import {
  ORDER_STATUS,
  OrderStatusOptions,
  OrderStatusOptionsFlows,
} from '../config/order-options'

export default {
  name: 'OrderStatusSelect',
  components: {
    vSelect,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OrderStatusOptions,
    }
  },
  computed: {
    options() {
      const orderStatus = this.status
      return OrderStatusOptions.filter(option => (OrderStatusOptionsFlows[orderStatus] ?? []).includes(option.value))
    },
    selectOptions() {
      return this.options.concat(this.value === ORDER_STATUS.PENDING ? [this.pendingOption] : [])
    },
    selected() {
      return OrderStatusOptions.find(option => option.value === this.status) ?? {}
    },
    pendingOption() {
      return OrderStatusOptions.find(option => option.value === ORDER_STATUS.PENDING) ?? {}
    },
    status() {
      return this.value
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
    getBadgeColor(selected) {
      return (selected.color || '').replace('gradient-', 'light-')
    },
    getDropdownColor(selected) {
      return (selected.color || '').replace('light-', 'outline-')
    },
  },
}
</script>

<style lang="scss">
.order-status-select-container {
  .order-status-select {
    display: block;
    width: 100%;
  }

  .arrow-icon {
    margin: 0 4px;
  }

  .vs__selected {
    margin-top: 0;
  }

  .vs__search {
    width: 0!important;
    height: 0!important;
  }

  .dropdown-menu {
    box-shadow: -1px 5px 31px 0px rgba(0,0,0,0.75);

    &.show {
      left: unset;
    }
  }

  .dropdown-toggle.btn:disabled {
    opacity: 1!important;
    cursor: not-allowed;
  }
}
</style>
