<template>
  <ok-modal
    :title="`${$t('orders.order-id')}: #MS-${order.id}`"
    :value="value"
    dialog-class="orders-modal"
    size="lg"
    @change="loading ? null : $emit('input', $event)"
  >
    <error-alert :error="error" />
    <b-row>
      <b-col
        v-if="order.name"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('fields.name') }}: </span>
          {{ order.name }}
        </div>
      </b-col>
      <b-col
        v-if="order.phone"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('fields.phone-number') }}: </span>
          <a
            :href="`tel:${order.phone}`"
            dir="ltr"
          >
            {{ order.phone }}
          </a>
        </div>
      </b-col>
      <b-col md="6">
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('orders.order-type') }}: </span>
          {{ $t(`orders.types.${order.order_type ? order.order_type : 'service'}`) }}
        </div>
      </b-col>
      <b-col
        v-if="order.city"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('fields.city') }}: </span>
          {{ $t('cities.' + order.city) }}
        </div>
      </b-col>
      <b-col
        v-if="order.address"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('fields.address') }}: </span>
          {{ order.address }}
        </div>
      </b-col>
      <b-col
        v-if="order.table"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('fields.table') }}: </span>
          {{ order.table }}
        </div>
      </b-col>
      <b-col
        v-if="order.room"
        md="6"
      >
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('tables.room') }}: </span>
          {{ order.room }}
        </div>
      </b-col>
      <b-col md="6">
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('orders.order-time') }}: </span>
          {{ time }}
        </div>
      </b-col>
      <b-col
        v-if="order.schedule"
        md="6"
      >
        <div
          class="invoice-date-wrapper"
        >
          <span class="font-weight-bold">{{ $t('orders.scheduled-at') }}: </span>
          {{ order.schedule }}
        </div>
      </b-col>
      <b-col md="6">
        <div class="invoice-date-wrapper  d-flex align-items-center">
          <span
            class="font-weight-bold mr-1"
          >{{ $t('orders.order-status') }}: </span>
          <order-status-select
            v-model="form.status"
            class="flex-grow-1"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div class="invoice-date-wrapper  d-flex align-items-center">
          <span
            class="font-weight-bold mr-1"
          >{{ $t('orders.payment-status') }}: </span>
          <b-form-checkbox
            v-model="form.paid"
            class="flex-grow-1"
            switch
          />
        </div>
      </b-col>
    </b-row>
    <div
      v-if="order.note"
      class="invoice-date-wrapper bg-warning text-white p-1"
    >
      <span class="font-weight-bold">{{ $t('general.note') }}: </span>
      {{ order.note }}
    </div>
    <div class="items-wrapper mt-1">
      <p class="font-weight-bold">
        {{ $t('orders.order-details') }}:
      </p>
      <div
        v-for="line in order.items"
        :key="line.id"
      >
        <div class="item-name">
          {{ getName(line.item) }}<span v-if="line.discount">
            ({{ $t('orders.with-discount', [line.discount]) }} )
          </span>
          <div
            :style="line.item === 'Removed' ? {textDecoration: 'line-through'} : {} "
            class="item-price"
          >
            {{ `${line.quantity} * ` }} <span
              :style="{textDecoration: line.discount ?'line-through':'none'}"
            >{{ line.price }}</span>
            <span v-if="line.discount"> {{ line.discounted_price }}</span>
            ={{ line.quantity * (line.discount ? line.discounted_price : line.price) }}
            {{ line.currency }}
          </div>
          <div
            v-if="line.note"
            class="text-warning"
            style="margin-top: 5px;"
          >
            {{ $t('general.note') }}: <span>{{ line.note }}</span>
          </div>
          <div
            v-if="line.modifiers.length"
            class="item-modifiers"
            style="margin-top: 5px;"
          >
            {{ $t('orders.modifiers') }}:
            <ul>
              <li
                v-for="modifier in line.modifiers"
                :key="modifier.id"
              >
                {{ `${getName(modifier)} =` }}
                <span :style="{textDecoration: modifier.discounted_price ?'line-through':'none'}">{{
                  modifier.price
                }}</span>
                <span v-if="modifier.discounted_price"> {{ modifier.discounted_price }}</span>
                {{ line.currency }}
              </li>
            </ul>
          </div>
        </div>
        <div class="item-total-price">
          {{ `${$t('orders.total-product-price')}: ${line.total_price} ${line.currency}` }}
        </div>
        <hr>
      </div>
      <div
        v-if="order.fees > 0"
        class="fees"
      >
        <span class="font-weight-bold">{{ $t('orders.delivery-fees') }}: </span>
        {{ order.fees + ' ' + originalCurrency }}
      </div>
      <div
        v-if="order.amount_no_coupon"
        class="invoice-date-wrapper"
      >
        <span class="font-weight-bold">{{ $t('orders.subtotal') }}: </span>
        {{ `${order.amount_no_coupon} ${originalCurrency}` }}
      </div>
      <div
        v-if="order.taxes_amount"
        class="invoice-date-wrapper"
      >
        <span class="font-weight-bold">{{ $t('orders.taxes') }}: </span>
        {{ `${order.taxes_amount} ${originalCurrency}` }}
      </div>
      <template v-if="order.coupon && order.amount_no_coupon!==order.amount">
        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('orders.coupon') }}: </span>
          <span v-if="order.coupon.discount_type==='fixed'">
            {{ `${order.coupon.discount_value} ${originalCurrency}` }}
          </span>
          <span v-else>
            {{ `${order.coupon.discount} %` }}
          </span>
        </div>

        <div class="invoice-date-wrapper">
          <span class="font-weight-bold">{{ $t('orders.final-total', [currency]) }}: </span>
          {{ `${order.total} ${currency}` }}
        </div>
      </template>
      <div
        v-else
        class="invoice-date-wrapper"
      >
        <span class="font-weight-bold">{{ $t('orders.final-total', [currency]) }}: </span>
        {{ `${order.total} ${currency}` }}
      </div>
    </div>
    <div
      v-if="showMap"
      class="mt-2"
    >
      <h4 class="mb-1">
        {{ $t('orders.location-on-map') }}
      </h4>
      <google-map
        :map-config="{zoom:16,center: order.location}"
      />
    </div>
    <template #actions>
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-button
          variant="outline-dark"
          @click="$emit('input', false)"
        >
          {{ $t('forms.close') }}
        </b-button>
        <b-button
          :disabled="loading || disabled"
          variant="dark"
          @click="submit()"
        >
          <span :class="{'pr-1': loading}">{{ $t('forms.save') }}</span>
          <b-spinner
            v-if="loading"
            small
          />
        </b-button>

      </div>

    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BCol, BFormCheckbox, BRow, BSpinner,
} from 'bootstrap-vue'
import OrdersAPIs from '@/api/store/order-api'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import LocalizationService from '@/services/localization-service'
import OkModal from '@/@bya3/components/modals/OkModal'
import GoogleMap from '@/@bya3/components/maps/GoogleMap'
import { OrderStatusOptions, PaymentStatusList } from '../config/order-options'
import OrderStatusSelect from './OrderStatusSelect'

export default {
  name: 'OrderModal',
  components: {
    OkModal,
    OrderStatusSelect,
    ErrorAlert,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
    GoogleMap,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {
        status: '',
        paid: '',
        deliver_with: '',
      },
      options: OrderStatusOptions,
      PaymentStatusList,
      disabled: false,
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency']
    },
    originalCurrency() {
      return this.order?.items?.[0]?.currency ?? this.currency
    },
    time() {
      const time = this.order?.created_at
      if (!time) return ''
      return time
    },
    showMap() {
      return this.order.order_type === 'delivery' && !!this.order?.location?.lat && !!this.order?.location?.lng
    },
  },
  watch: {
    value() {
      this.error = null
    },
    order() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form.status = this.order.status
      this.form.paid = !!this.order.paid
    },
    getName(modifier) {
      return LocalizationService.getTranslate(modifier)
    },
    async submit() {
      this.loading = true
      const form = { ...this.form, id: this.order.id }
      await OrdersAPIs.process(form)
        .then(response => {
          if (response?.error === 0) {
            this.$emit('updated')
            this.$emit('input', false)
            this.$notifySuccess(response.message)
          } else {
            this.$notifyError(response.message)
          }
        })
        .catch(e => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss">
.orders-modal {
  .modal-title {
    color: var(--secondary);
    font-weight: 600;
    font-size: 16px;
    padding-right: 16px;
  }

  .order-content {
    max-height: 75vh;
    overflow-y: auto;
  }

  .invoice-date-wrapper {
    margin-top: 10px;
  }

  .items-wrapper {
    background-color: #F7F6F6;
    padding: 10px;

    .dark-layout & {
      background-color: var(--theme-dark-body-bg) !important;
    }

    .item-name {
      margin-top: 1px;
      line-height: 1.8;
    }
  }
}
</style>
